import { FIXED_PERIOD_OPTIONS } from './components/FilterDrawer';

const LOCAL_STORAGE_KEY = 'reporting-filters-';

export function loadLocalStorage(entityId) {
  return (
    JSON.parse(localStorage.getItem(`${LOCAL_STORAGE_KEY}${entityId}`)) || {}
  );
}

export function loadFilterValues(key, entityId) {
  const localStorageData = loadLocalStorage(entityId);

  return localStorageData[key];
}

export function loadUpdatedFilterValues(key, entityId) {
  const filterValue = loadLocalStorage(entityId);
  if (filterValue && filterValue.period) {
    const updatedPeriod = FIXED_PERIOD_OPTIONS.find(
      (period) => period.value === filterValue.period.selectedOption
    );

    if (updatedPeriod) {
      const { value, period, compare } = updatedPeriod;
      const updatedLocalStorageData = {
        period: {
          ...period,
          compare,
          selectedOption: value,
        },
      };

      localStorage.setItem(
        `${LOCAL_STORAGE_KEY}${entityId}`,
        JSON.stringify(updatedLocalStorageData)
      );

      return updatedLocalStorageData[key];
    }
  }

  return;
}

function setLocalStorage(key, value, entityId) {
  const localStorageData = loadLocalStorage(entityId);

  const updatedLocalStorageData = {
    ...localStorageData,
    [key]: value,
  };

  localStorage.setItem(
    `${LOCAL_STORAGE_KEY}${entityId}`,
    JSON.stringify(updatedLocalStorageData)
  );
}

function removeLocalStorage(entityId) {
  localStorage.removeItem(`${LOCAL_STORAGE_KEY}${entityId}`);
}

export function set(key, value, entityId) {
  setLocalStorage(key, value, entityId);
}

export function remove(entityId) {
  removeLocalStorage(entityId);
}
