import Drawer from '@material-ui/core/Drawer';
import CancelIcon from '@material-ui/icons/Cancel';
import api from 'api';
import styles from 'modules/reporting/hooks/css/useFilterState.css';
import { drawerStyles } from 'modules/reporting/hooks/useFilterState';
import BorderedCalendarPicker from 'modules/shared/components/inputs/BorderedCalendarPicker';
import BorderedSelect from 'modules/shared/components/inputs/BorderedSelect';
import moment from 'moment';
import React, {
  Fragment,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { setReportingPeriod, setReportingTier } from '../../actions';
import { FEATURE_FLAGS } from 'conf';

const defaultPeriod =
  FEATURE_FLAGS.FEATURE_FLAG_DASHBOARD_DEFAULT_PERIOD_FILTER_ALL
    ? 'All'
    : 'Last 90 days';

type Props = {
  accessToken: string;
  entityId: string;
  dispatch: any;
  isOpen: boolean;
  isStandardUser: boolean;
  onToggleIsOpen?: () => void;
  period: {
    from: moment.Moment;
    to: moment.Moment;
    selectedOption: string;
  };
  tier: string;
};

type TierOption = {
  label: string;
  value: string;
};

const oneDayBeforeOneYearLater = (startDate: Date | string): moment.Moment =>
  moment(startDate).add(1, 'year').subtract(1, 'day');

const CALENDAR_FORMAT = 'DD/MM/YYYY';
const DEFAULT_FORMAT = 'YYYY-MM-DD';
const DEFAULT_MIN_DATE = '2016-11-25';

const getCalendarDate = (date) => {
  if (!date) {
    return undefined;
  }
  const [defaultFormatDate] = date.split('T');
  return moment(defaultFormatDate, DEFAULT_FORMAT).format(CALENDAR_FORMAT);
};

// FIXED DATE VALUES
const today = moment().format(DEFAULT_FORMAT);
const yesterday = moment().subtract(1, 'days').format(DEFAULT_FORMAT);
const twoDaysAgo = moment().subtract(2, 'days').format(DEFAULT_FORMAT);
const thisWeekStart = moment().startOf('week').format(DEFAULT_FORMAT);
const lastWeekStart = moment()
  .startOf('week')
  .subtract(1, 'week')
  .format(DEFAULT_FORMAT);
const lastWeekEnd = moment()
  .startOf('week')
  .subtract(1, 'day')
  .format(DEFAULT_FORMAT);
const defaultMinDate = moment(DEFAULT_MIN_DATE).format(DEFAULT_FORMAT);

const getComparePeriod = (days: number) => ({
  from: moment()
    .subtract(days * 2, 'days')
    .format(DEFAULT_FORMAT),
  to: moment()
    .subtract(days + 1, 'days')
    .format(DEFAULT_FORMAT),
});

export const FIXED_PERIOD_OPTIONS = [
  {
    label: 'All',
    period: { from: defaultMinDate, to: today },
    value: 'All',
  },
  {
    label: 'Today',
    period: { from: today, to: today },
    compare: { from: yesterday, to: yesterday },
    value: 'Today',
  },
  {
    label: 'Yesterday',
    period: { from: yesterday, to: yesterday },
    compare: { from: twoDaysAgo, to: twoDaysAgo },
    value: 'Yesterday',
  },
  {
    label: 'This week',
    period: { from: thisWeekStart, to: today },
    compare: { from: lastWeekStart, to: lastWeekEnd },
    value: 'This week',
  },
  {
    label: 'Last 7 days',
    period: {
      from: moment().subtract(7, 'days').format(DEFAULT_FORMAT),
      to: today,
    },
    compare: getComparePeriod(7),
    value: 'Last 7 days',
  },
  {
    label: 'Last 30 days',
    period: {
      from: moment().subtract(30, 'days').format(DEFAULT_FORMAT),
      to: today,
    },
    compare: getComparePeriod(30),
    value: 'Last 30 days',
  },
  {
    label: 'Last 60 days',
    period: {
      from: moment().subtract(60, 'days').format(DEFAULT_FORMAT),
      to: today,
    },
    compare: getComparePeriod(60),
    value: 'Last 60 days',
  },
  {
    label: 'Last 90 days',
    period: {
      from: moment().subtract(90, 'days').format(DEFAULT_FORMAT),
      to: today,
    },
    compare: getComparePeriod(90),
    value: 'Last 90 days',
  },
];

function FilterDrawer({
  accessToken,
  dispatch,
  entityId,
  isOpen,
  onToggleIsOpen,
  period,
  tier,
}: Props): ReactElement {
  const { from, to, selectedOption } = period;

  const PERIODS_OPTIONS = [
    {
      label: 'Financial year',
      period: { from, to },
      compare: {
        from: moment(from)
          .subtract(1, 'year')
          .add(1, 'day')
          .format(DEFAULT_FORMAT),
        to: moment(from).format(DEFAULT_FORMAT),
      },
      value: 'Financial year',
    },
    ...FIXED_PERIOD_OPTIONS,
  ];

  const [timePeriod, setTimePeriod] = useState(selectedOption || defaultPeriod);
  const [tierOptions, setTierOptions] = useState<TierOption[] | null>(null);
  const hasTierOptions = tierOptions && tierOptions.length > 0;

  const onChangeFinancialYearValue = (name: string, inputValue: Date) => {
    const value = moment(
      moment(inputValue, CALENDAR_FORMAT).format(DEFAULT_FORMAT)
    ).toDate();

    const payload = {
      ...period,
      [name]: value,
    };

    if (name === 'from') {
      payload['to'] = oneDayBeforeOneYearLater(value);
    }

    dispatch(setReportingPeriod(payload));
  };

  const onChangePeriodValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedOption = PERIODS_OPTIONS.find(
      (option) => option.value === event.target.value
    );

    if (!selectedOption) {
      return;
    }

    const { compare, period: selectedPeriod, value } = selectedOption;
    const keyValues = selectedPeriod;

    setTimePeriod(value);
    dispatch(
      setReportingPeriod({
        ...keyValues,
        compare,
        selectedOption: value,
      })
    );
  };

  const onChangeTierValue = (selectedEntityId) => {
    dispatch(
      setReportingTier(selectedEntityId === 'All' ? null : selectedEntityId)
    );
  };

  const resetFilterParams = () => {
    setTimePeriod(defaultPeriod);
    const selectedOption = PERIODS_OPTIONS.find(
      (option) => option.value === defaultPeriod
    );

    if (!selectedOption) {
      return;
    }

    const { compare, period: selectedPeriod, value } = selectedOption;
    const keyValues = selectedPeriod;
    dispatch(
      setReportingPeriod({
        ...keyValues,
        compare,
        selectedOption: value,
      })
    );
    dispatch(setReportingTier(null), false);
  };

  const getReportOptions = (data): TierOption[] => {
    const reportOptions: TierOption[] = [];

    data.report_options.entity_options.children.forEach(
      ({ id, children, trading_name }) => {
        const options = [
          {
            label: trading_name,
            value: id,
          },
        ];

        if (children) {
          children.map(({ id, trading_name }) => {
            options.push({ label: `— ${trading_name}`, value: id });
          });
        }

        reportOptions.push(...options);
      }
    );

    return reportOptions;
  };

  const onFetchTierOptions = useCallback(() => {
    (async () => {
      const optionsApi = api('reporting_tier_options', accessToken, entityId);

      try {
        const { data } = await optionsApi.get();

        const reportOptions = getReportOptions(data);

        if (reportOptions.length === 0) {
          return setTierOptions([]);
        }

        const options = [{ label: 'All', value: 'All' }];
        Array.prototype.push.apply(options, reportOptions);

        setTierOptions(options);
      } catch (error) {
        setTierOptions([]);
      }
    })();
  }, [accessToken, entityId]);

  useEffect(() => {
    if (!tierOptions) {
      onFetchTierOptions();
    }
  }, [onFetchTierOptions, tierOptions]);

  return (
    <Drawer
      anchor="right"
      open={isOpen}
      variant="persistent"
      classes={drawerStyles()}
    >
      <div className={styles.header}>
        <CancelIcon className={styles.cancel_icon} onClick={onToggleIsOpen} />
        <div
          role="button"
          className={styles.reset_button}
          onClick={resetFilterParams}
          onKeyUp={resetFilterParams}
          tabIndex={0}
        >
          reset
        </div>
      </div>
      <div className={styles.filters}>
        <br />
        <BorderedSelect
          textFieldProps={{
            label: 'Time period',
          }}
          label="Time period"
          value={timePeriod}
          options={PERIODS_OPTIONS}
          onChange={onChangePeriodValue}
        />
        {timePeriod === 'Financial year' && (
          <Fragment>
            <BorderedCalendarPicker
              name="period_from"
              textFieldProps={{
                label: 'Financial year start date',
              }}
              value={getCalendarDate(from)}
              minDate={moment(DEFAULT_MIN_DATE).toDate()}
              maxDay={moment().toDate()}
              onChange={(date) => onChangeFinancialYearValue('from', date)}
            />
            <BorderedCalendarPicker
              name="period_to"
              textFieldProps={{
                label: 'Financial year end date',
              }}
              isDisabled
              value={getCalendarDate(to)}
              minDate={moment(from).toDate()}
              maxDay={moment().toDate()}
              onChange={(date) => onChangeFinancialYearValue('to', date)}
            />
          </Fragment>
        )}
        {hasTierOptions && (
          <BorderedSelect
            textFieldProps={{
              label: 'Tiers',
            }}
            label="Tiers"
            options={tierOptions}
            value={tier ? tier : 'All'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              onChangeTierValue(e.target.value)
            }
          />
        )}
      </div>
    </Drawer>
  );
}

FilterDrawer.defaultProps = {};

export default FilterDrawer;
