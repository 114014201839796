const NEW_LOGIN_IMAGE = window._env_.FEATURE_FLAGS.NEW_LOGIN_IMAGE;
const SAML_FLOW = window._env_.FEATURE_FLAGS.SAML_FLOW;
const NEW_USERS_LIST_HEADER = window._env_.FEATURE_FLAGS.NEW_USERS_LIST_HEADER;
const REVAMPED_1CAD = window._env_.FEATURE_FLAGS.REVAMPED_1CAD;
const FEATURE_FLAG_1CAF_ACCOUNT_TYPES =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_1CAF_ACCOUNT_TYPES;
const FEATURE_FLAG_SECURE_S3 =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_SECURE_S3;
const NEW_APPLICATION_EVENTS =
  window._env_.FEATURE_FLAGS.NEW_APPLICATION_EVENTS;
const NEW_LEAD_EVENT = window._env_.FEATURE_FLAGS.NEW_LEAD_EVENT;
const FEATURE_FLAG_WATCHTOWER =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER;
const FEATURE_FLAG_AUTHENTICITY_SCORE =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_AUTHENTICITY_SCORE;
const FEATURE_FLAG_DROPDOWN_TIER =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_DROPDOWN_TIER;
const FEATURE_FLAG_USER_PROFILE_UX =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_USER_PROFILE_UX;
const FEATURE_FLAG_RAPID_TRANSFER =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_RAPID_TRANSFER || false;
const WATCHTOWER_COMPREHENSIVE_FETCH =
  window._env_.FEATURE_FLAGS.WATCHTOWER_COMPREHENSIVE_FETCH;
const FEATURE_FLAG_NEW_NAV_HEADER =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_NEW_NAV_HEADER;
const FEATURE_FLAG_CUSTOMER_PIPELINE =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_CUSTOMER_PIPELINE;
const FEATURE_FLAG_ZENDESK_CHAT =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_ZENDESK_CHAT;
const FEATURE_FLAG_ACCOUNT_MONITORING =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_ACCOUNT_MONITORING;
const FEATURE_FLAG_1CAF_CAT_0 =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_1CAF_CAT_0;
const FEATURE_FLAG_EQUIFAX_FIELDS =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_EQUIFAX_FIELDS;
const FEATURE_FLAGS_AUTH_TNCS =
  window._env_.FEATURE_FLAGS.FEATURE_FLAGS_AUTH_TNCS;
const FEATURE_FLAG_EMAIL_T3 = window._env_.FEATURE_FLAGS.FEATURE_FLAG_EMAIL_T3;
const FEATURE_FLAG_CUSTOMER_PIN =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_CUSTOMER_PIN;
const FEATURE_FLAG_1ACCOUNT_CUSTOMER_VIEW =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_1ACCOUNT_CUSTOMER_VIEW;
const FEATURE_FLAG_IUF_PERFORMANCE_FIX =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_IUF_PERFORMANCE_FIX;
const FEATURE_FLAG_1CAD_CASH_RULE_CHANGES =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_1CAD_CASH_RULE_CHANGES;
const FEATURE_FLAG_AUS_AUTO_POPULATE_DIRECTORS =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_AUS_AUTO_POPULATE_DIRECTORS;
const FEATURE_FLAG_1CAF_CAT_6 =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_1CAF_CAT_6;
const FEATURE_FLAG_NEW_PREVIEW_LAYOUT =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_NEW_PREVIEW_LAYOUT;
const FEATURE_FLAG_GUARANTOR_FIXES =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_GUARANTOR_FIXES;
const FEATURE_FLAG_EXPERIENCE_MAP_TOOLTIPS =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_EXPERIENCE_MAP_TOOLTIPS;
const FEATURE_FLAG_EQUIFAX_CONSUMER =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_EQUIFAX_CONSUMER;
const FEATURE_FLAG_PPSR_PERMISSIONING =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_PPSR_PERMISSIONING;
const FEATURE_FLAG_ADDITIONAL_FORM_FIX =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_ADDITIONAL_FORM_FIX;
const FEATURE_FLAG_GUARANTOR_FLOW_AGREEMENTS =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_GUARANTOR_FLOW_AGREEMENTS;
const FEATURE_FLAG_ID_CHECK_UPDATES =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_ID_CHECK_UPDATES;
const FEATURE_FLAG_TRUST_FLOW_GUARANTORS =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_GUARANTORS;
const FEATURE_FLAG_BC_FLOW_GUARANTOR_FIX =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_BC_FLOW_GUARANTOR_FIX;
const FEATURE_FLAG_TEMP_BLOCK_QLD_DL =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_TEMP_BLOCK_QLD_DL;
const FEATURE_FLAG_OTHER_ID_TYPE =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_OTHER_ID_TYPE;
const FEATURE_FLAG_REFACTORED_SCRAPE_LOGIC =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_REFACTORED_SCRAPE_LOGIC;
const FEATURE_FLAG_MAX_RETRY_CAT6 =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_MAX_RETRY_CAT6;
const FEATURE_FLAG_UPDATED_TNCS =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_UPDATED_TNCS;
const FEATURE_FLAG_UPDATED_TRCS =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_UPDATED_TRCS;
const FEATURE_FLAG_WATCHTOWER_EMAIL_DOMAINS =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER_EMAIL_DOMAINS;
const FEATURE_FLAG_UPDATED_SOLE_TRADER =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_UPDATED_SOLE_TRADER;
const FEATURE_FLAG_EQUIFAX_COMMERCIAL_PLUS =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_EQUIFAX_COMMERCIAL_PLUS;
const FEATURE_FLAG_WATCHTOWER_EXCLUSIONS_FIX =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER_EXCLUSIONS_FIX;
const FEATURE_FLAG_1CAD_CREDIT_CHECK_FIX =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_1CAD_CREDIT_CHECK_FIX;
const FEATURE_FLAG_TRUST_FLOW_AUTO_POPULATE_DIRECTORS =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_TRUST_FLOW_AUTO_POPULATE_DIRECTORS;
const FEATURE_FLAG_DUPLICATE_AUTH_FIX =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_DUPLICATE_AUTH_FIX;
const FEATURE_FLAG_PIPELINE_PAGE_UI_UPDATE =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_PIPELINE_PAGE_UI_UPDATE;
const FEATURE_FLAG_DASHBOARD_DEFAULT_PERIOD_FILTER_ALL =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_DASHBOARD_DEFAULT_PERIOD_FILTER_ALL;
const FEATURE_FLAG_GUARANTOR_TNC_UX_UPDATE =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_GUARANTOR_TNC_UX_UPDATE;
const FEATURE_FLAG_UPDATED_WATCHTOWER_VCF =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_UPDATED_WATCHTOWER_VCF;
const FEATURE_FLAG_DIRECT_DEBIT_TOGGLE =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_DIRECT_DEBIT_TOGGLE;
const FEATURE_FLAG_WATCHTOWER_ACCOUNT_MONITORING_CONTACT =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_WATCHTOWER_ACCOUNT_MONITORING_CONTACT;
const FEATURE_FLAG_FARMLANDS_ID_UPLOAD_UPDATES =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_FARMLANDS_ID_UPLOAD_UPDATES;
const FEATURE_FLAG_IMPROVED_NOTES =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_IMPROVED_NOTES;
const FEATURE_FLAG_SIGNATURE_FONT =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_SIGNATURE_FONT;
const FEATURE_FLAG_CMM_AUTHORISATION_PAGE_UPDATE =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_CMM_AUTHORISATION_PAGE_UPDATE;
const FEATURE_FLAG_RAPID_TRANSFER_UPDATES =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_RAPID_TRANSFER_UPDATES;
const FEATURE_FLAG_TERMS_DOCUMENT_VERSIONING =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_TERMS_DOCUMENT_VERSIONING;
const FEATURE_FLAG_SHADOW_VCF =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_SHADOW_VCF;
const FEATURE_FLAG_NEW_GUARANTOR_FIELDS =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_NEW_GUARANTOR_FIELDS;
const FEATURE_FLAG_LOADED_IN_SYSTEM_CHECKS =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_LOADED_IN_SYSTEM_CHECKS;
const FEATURE_FLAG_STREAMLINED_DATEPICKER =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_STREAMLINED_DATEPICKER;
const FEATURE_FLAG_CREDITWORKS_UI_REFRESH =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_CREDITWORKS_UI_REFRESH;
const FEATURE_FLAG_AUTHENTICITY_SCORE_VERSIONING =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_AUTHENTICITY_SCORE_VERSIONING;
const FEATURE_FLAG_1CAD_JUDGEMENT_DEFAULT_MANDATORY =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_1CAD_JUDGEMENT_DEFAULT_MANDATORY;
const FEATURE_FLAG_UPDATED_FINANCIALS_UI =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_UPDATED_FINANCIALS_UI;
const FEATURE_FLAG_AUTOMATED_PENDING_STATUSES =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_AUTOMATED_PENDING_STATUSES;
const FEATURE_FLAG_FRAUDULENT_APP_UPDATED_VCF =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_FRAUDULENT_APP_UPDATED_VCF;
const FEATURE_FLAG_UPDATED_WT_PIPELINE =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_UPDATED_WT_PIPELINE;
const FEATURE_FLAG_FIXED_DASHBOARD_FILTER =
  window._env_.FEATURE_FLAGS.FEATURE_FLAG_FIXED_DASHBOARD_FILTER;

export const FEATURE_FLAGS = {
  NEW_LOGIN_IMAGE,
  SAML_FLOW,
  NEW_USERS_LIST_HEADER,
  REVAMPED_1CAD,
  NEW_APPLICATION_EVENTS,
  NEW_LEAD_EVENT,
  FEATURE_FLAG_1CAF_ACCOUNT_TYPES,
  FEATURE_FLAG_SECURE_S3,
  FEATURE_FLAG_WATCHTOWER,
  FEATURE_FLAG_AUTHENTICITY_SCORE,
  FEATURE_FLAG_DROPDOWN_TIER,
  FEATURE_FLAG_USER_PROFILE_UX,
  FEATURE_FLAG_RAPID_TRANSFER,
  WATCHTOWER_COMPREHENSIVE_FETCH,
  FEATURE_FLAG_NEW_NAV_HEADER,
  FEATURE_FLAG_CUSTOMER_PIPELINE,
  FEATURE_FLAG_ZENDESK_CHAT,
  FEATURE_FLAG_ACCOUNT_MONITORING,
  FEATURE_FLAG_1CAF_CAT_0,
  FEATURE_FLAG_EQUIFAX_FIELDS,
  FEATURE_FLAGS_AUTH_TNCS,
  FEATURE_FLAG_EMAIL_T3,
  FEATURE_FLAG_CUSTOMER_PIN,
  FEATURE_FLAG_1ACCOUNT_CUSTOMER_VIEW,
  FEATURE_FLAG_IUF_PERFORMANCE_FIX,
  FEATURE_FLAG_1CAD_CASH_RULE_CHANGES,
  FEATURE_FLAG_AUS_AUTO_POPULATE_DIRECTORS,
  FEATURE_FLAG_EQUIFAX_CONSUMER,
  FEATURE_FLAG_1CAF_CAT_6,
  FEATURE_FLAG_NEW_PREVIEW_LAYOUT,
  FEATURE_FLAG_GUARANTOR_FIXES,
  FEATURE_FLAG_PPSR_PERMISSIONING,
  FEATURE_FLAG_ADDITIONAL_FORM_FIX,
  FEATURE_FLAG_GUARANTOR_FLOW_AGREEMENTS,
  FEATURE_FLAG_ID_CHECK_UPDATES,
  FEATURE_FLAG_TRUST_FLOW_GUARANTORS,
  FEATURE_FLAG_BC_FLOW_GUARANTOR_FIX,
  FEATURE_FLAG_TEMP_BLOCK_QLD_DL,
  FEATURE_FLAG_EXPERIENCE_MAP_TOOLTIPS,
  FEATURE_FLAG_OTHER_ID_TYPE,
  FEATURE_FLAG_REFACTORED_SCRAPE_LOGIC,
  FEATURE_FLAG_MAX_RETRY_CAT6,
  FEATURE_FLAG_UPDATED_TNCS,
  FEATURE_FLAG_UPDATED_TRCS,
  FEATURE_FLAG_WATCHTOWER_EMAIL_DOMAINS,
  FEATURE_FLAG_UPDATED_SOLE_TRADER,
  FEATURE_FLAG_EQUIFAX_COMMERCIAL_PLUS,
  FEATURE_FLAG_WATCHTOWER_EXCLUSIONS_FIX,
  FEATURE_FLAG_1CAD_CREDIT_CHECK_FIX,
  FEATURE_FLAG_TRUST_FLOW_AUTO_POPULATE_DIRECTORS,
  FEATURE_FLAG_DUPLICATE_AUTH_FIX,
  FEATURE_FLAG_PIPELINE_PAGE_UI_UPDATE,
  FEATURE_FLAG_DASHBOARD_DEFAULT_PERIOD_FILTER_ALL,
  FEATURE_FLAG_GUARANTOR_TNC_UX_UPDATE,
  FEATURE_FLAG_UPDATED_WATCHTOWER_VCF,
  FEATURE_FLAG_DIRECT_DEBIT_TOGGLE,
  FEATURE_FLAG_WATCHTOWER_ACCOUNT_MONITORING_CONTACT,
  FEATURE_FLAG_FARMLANDS_ID_UPLOAD_UPDATES,
  FEATURE_FLAG_IMPROVED_NOTES,
  FEATURE_FLAG_SIGNATURE_FONT,
  FEATURE_FLAG_CMM_AUTHORISATION_PAGE_UPDATE,
  FEATURE_FLAG_RAPID_TRANSFER_UPDATES,
  FEATURE_FLAG_TERMS_DOCUMENT_VERSIONING,
  FEATURE_FLAG_SHADOW_VCF,
  FEATURE_FLAG_NEW_GUARANTOR_FIELDS,
  FEATURE_FLAG_LOADED_IN_SYSTEM_CHECKS,
  FEATURE_FLAG_STREAMLINED_DATEPICKER,
  FEATURE_FLAG_CREDITWORKS_UI_REFRESH,
  FEATURE_FLAG_AUTHENTICITY_SCORE_VERSIONING,
  FEATURE_FLAG_1CAD_JUDGEMENT_DEFAULT_MANDATORY,
  FEATURE_FLAG_UPDATED_FINANCIALS_UI,
  FEATURE_FLAG_AUTOMATED_PENDING_STATUSES,
  FEATURE_FLAG_FRAUDULENT_APP_UPDATED_VCF,
  FEATURE_FLAG_UPDATED_WT_PIPELINE,
  FEATURE_FLAG_FIXED_DASHBOARD_FILTER,
};

type flagNames = typeof FEATURE_FLAGS;
export type FEATURE_FLAGS_TYPE = keyof flagNames;
