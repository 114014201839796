import React, { Fragment } from 'react';
import {
  LinkedApplicationWrapper,
  WatchtowerAlertRowWrapper,
  WatchtowerAlertTableWrapper,
  WatchtowerAlertTag,
  WatchtowerTableHeaderWrapper,
} from '../styles';
import { formatLocalTime } from 'utils/dateFormatter';

// @ts-ignore-next-line: TS is not able to find `Link` as an exported value of react-router but definitely there is
import { Link } from 'react-router';
import formatMoney from 'utils/formatMoney';
import { linkedApplicationAlertKeys } from '../constants';

export const getAlertTier = ({ linkedApplication, entityTierData }) => {
  const linkedSupplierId = linkedApplication.supplier_id;

  const hasOtherTiers = entityTierData.some(
    (tierData) => tierData.id !== linkedSupplierId
  );

  if (!hasOtherTiers) return null;

  const alertTier = entityTierData.find(
    (tierData) => tierData.id === linkedSupplierId
  );

  return alertTier;
};

export const DataRowLinkedApplicationText = ({
  linkedApplication,
  entityTierData,
  currentEntityId = undefined,
}) => {
  if (!linkedApplication) return <span>-</span>;

  const tierData = getAlertTier({ linkedApplication, entityTierData });

  const supplierIdText =
    linkedApplication && currentEntityId
      ? `?wt_app_entity=${currentEntityId}`
      : '';

  return (
    <React.Fragment>
      {tierData ? (
        <LinkedApplicationWrapper>
          <Link
            key={linkedApplication.application_name}
            to={`/dashboard/applications/${linkedApplication.application_id}/watchtower${supplierIdText}`}
          >
            {linkedApplication.application_name}
          </Link>
        </LinkedApplicationWrapper>
      ) : (
        <span>{linkedApplication.application_name}</span>
      )}
    </React.Fragment>
  );
};

type DataRowTierText = {
  linkedApplications?: any[];
  linkedApplication?: any;
  entityTierData: any;
};

export const DataRowTierText = ({
  linkedApplications,
  linkedApplication,
  entityTierData,
}: DataRowTierText) => {
  const application =
    (linkedApplications ? linkedApplications[0] : linkedApplication) || {};
  const linkedSupplierId = application.supplier_id;

  const applicationTier = entityTierData.find(
    (tier) => tier.id === linkedSupplierId
  );

  return <span>{applicationTier ? applicationTier.title : '-'}</span>;
};

const WatchtowerTableHeader = ({ alertName }) => {
  const hasLinkedApplications = linkedApplicationAlertKeys.includes(alertName);

  return (
    <WatchtowerTableHeaderWrapper linked={hasLinkedApplications}>
      <div>Alert date</div>
      <div>Alert trigger</div>
      {hasLinkedApplications && (
        <Fragment>
          <div>Linked</div>
          <div>Limit</div>
          <div>Start date</div>
          <div>Tier</div>
        </Fragment>
      )}
    </WatchtowerTableHeaderWrapper>
  );
};

const WatchtowerAlertRow = ({
  alertApplication,
  entityTierData,
  alertName,
  currentEntityId = undefined,
}) => {
  const {
    alert_on_resource,
    alert_application_data,
    application_limit,
    application_started_at,
  } = alertApplication;

  const { status, created_at } = alert_application_data;
  const isActioned = status !== 'open';

  const hasLinkedApplications = linkedApplicationAlertKeys.includes(alertName);

  return (
    <WatchtowerAlertRowWrapper linked={hasLinkedApplications}>
      <div>
        {formatLocalTime(created_at, 'DD/MM/YYYY')}{' '}
        {!isActioned && (
          <WatchtowerAlertTag label="new">NEW</WatchtowerAlertTag>
        )}
      </div>
      <div>{alert_on_resource}</div>
      {hasLinkedApplications && (
        <Fragment>
          <div>
            <DataRowLinkedApplicationText
              linkedApplication={alertApplication}
              entityTierData={entityTierData}
              currentEntityId={currentEntityId}
            />
          </div>
          <div>{`$${formatMoney(application_limit || 0)}`}</div>
          <div>{formatLocalTime(application_started_at, 'DD/MM/YYYY')}</div>
          <div>
            <DataRowTierText
              linkedApplication={alertApplication}
              entityTierData={entityTierData}
            />
          </div>
        </Fragment>
      )}
    </WatchtowerAlertRowWrapper>
  );
};

const WatchtowerAlertTable = ({
  linkedApplications,
  entityTierData,
  alertName,
  currentEntityId = undefined,
}) => {
  return (
    <WatchtowerAlertTableWrapper>
      <WatchtowerTableHeader alertName={alertName} />
      {linkedApplications.map((alertApplicationData) => (
        <WatchtowerAlertRow
          key={alertApplicationData.application_id}
          alertApplication={alertApplicationData}
          entityTierData={entityTierData}
          alertName={alertName}
          currentEntityId={currentEntityId}
        />
      ))}
    </WatchtowerAlertTableWrapper>
  );
};

export default WatchtowerAlertTable;
